.svg-HLI-icon-circle-minus-white-dims {
	width: 80px;
	height: 80px;
}

.svg-HLI-icon-circle-plus-white-dims {
	width: 80px;
	height: 80px;
}

.svg-HLI-icon-footer-email-white-dims {
	width: 100px;
	height: 95.4px;
}

.svg-HLI-icon-footer-phone-white-dims {
	width: 100px;
	height: 95.4px;
}

.svg-HLI-icon-footer-pin-white-dims {
	width: 48px;
	height: 60px;
}

.svg-HLI-icon-social-facebook-white-dims {
	width: 25.5px;
	height: 25.5px;
}

.svg-HLI-icon-social-linkedin-white-dims {
	width: 25.5px;
	height: 25.5px;
}

.svg-HLI-icon-social-twitter-white-dims {
	width: 25.5px;
	height: 25.5px;
}

.svg-HLI-icon-social-youtube-white-dims {
	width: 25.5px;
	height: 25.5px;
}

.svg-HLI-logo-Inline-TM-RGB-Dark-Blue-dims {
	width: 301.7px;
	height: 35.5px;
}

.svg-HLIQ-WGS-logo-color-dims {
	width: 438.6px;
	height: 170.4px;
}

.svg-HLIQ-WGS-logo-white-dims {
	width: 438.6px;
	height: 170.4px;
}

.svg-WGS-Details-Icons-Clinical-dims {
	width: 45.5px;
	height: 45.5px;
}

.svg-WGS-Details-Icons-Health-Insights-dims {
	width: 45.5px;
	height: 45.5px;
}

.svg-WGS-Details-Icons-Personal-Insights-dims {
	width: 45.5px;
	height: 45.5px;
}

.svg-WGS-Details-Icons-Traits-dims {
	width: 45.5px;
	height: 45.5px;
}

