/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.hli-icon-HLI-Stack {
  background-image: url(../sprites/hliHomeSpriteFile.png);
  background-position: 0px -20px;
  width: 475px;
  height: 276px;
}
.hli-icon-WGS-Details-1440x700 {
  background-image: url(../sprites/hliHomeSpriteFile.png);
  background-position: 0px -296px;
  width: 675px;
  height: 331px;
}
.hli-icon-red-oval {
  background-image: url(../sprites/hliHomeSpriteFile.png);
  background-position: 0px 0px;
  width: 20px;
  height: 20px;
}
